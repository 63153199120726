<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card-title>
                    <h1>Order Fulfillment</h1>
                    <v-spacer />
                    <v-btn
                        elevation="2"
                        color="#33cc99"
                        dark
                        class="ml-10" 
                        @click="$router.push('/fulfillment-calendar')"
                        >
                        <v-icon left>mdi-calendar</v-icon>
                        Delivery Calendar
                    </v-btn>   
                </v-card-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-sheet elevation="4">
                    <v-data-table
                        :headers="orderTableHeaders"
                        :items="filteredSalesOrders"
                        :items-per-page="500"
                        dense
                        show-expand
                        single-expand
                        item-key="order_id"
                        group-by="order_date"
                        @item-expanded="fetchProductionPlanDetails"
                    >
                        <!-- Filters -->
                        <template v-slot:top>
                            <v-container fluid>
                                <v-row>
                                    <!-- Search -->
                                    <v-col cols="12" sm="2">
                                        <v-text-field
                                            ref="searchField"
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Search"
                                            single-line
                                            hide-details
                                            dense
                                            outlined
                                            clearable
                                        ></v-text-field>
                                    </v-col>
                                    <!-- Branch Filter -->
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="selectedBranch"
                                            :items="branches"
                                            label="Select Branch"
                                            item-text="branch_name"
                                            item-value="branch_name"
                                            multiple
                                            chips
                                            small-chips
                                            deletable-chips
                                            outlined
                                            dense
                                            clearable
                                        ></v-select>
                                    </v-col>
                                    <!-- Payment Status Filter -->
                                    <v-col cols="12" sm="2">
                                        <v-select
                                            v-model="selectedPaymentStatus"
                                            :items="paymentStatusOptions"
                                            label="Payment Status"
                                            outlined
                                            dense
                                            clearable
                                        ></v-select>
                                    </v-col>
                                    <!-- Production Status Filter -->
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="selectedProductionStatus"
                                            :items="productionStatusOptions"
                                            label="Production Status"
                                            multiple
                                            chips
                                            small-chips
                                            deletable-chips
                                            outlined
                                            dense
                                            clearable
                                        ></v-select>
                                    </v-col>
                                    <!--Delivery date-->
                                    <v-col cols="12" sm="2">
                                        <v-menu
                                            ref="deliveryDateMenu"
                                            v-model="deliveryDateMenu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="selectedDeliveryDate"
                                                    label="Delivery Date"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    dense
                                                    outlined
                                                    clearable
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="selectedDeliveryDate" no-title @input="deliveryDateMenu = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <!-- Group by order date -->
                        <template v-slot:group.header="{ items }">
                            <th
                            :colspan="orderTableHeaders.length"
                            class="indigo lighten-5"
                            style="height: 36px"
                            >
                            {{
                                new Date(items[0].order_date).toLocaleDateString("en-GB", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                })
                            }}
                            </th>
                        </template>
                        <!-- Customer Name -->
                        <template v-slot:item.customer.customer_name="{ item }">
                            <span v-if="item.customer" class="customer-name" @click="openCustomerDetailsDialog(item.customer)">
                                {{ item.customer.customer_name }}
                            </span>
                            <span v-else>
                                Unknown Customer
                            </span>
                        </template>

                        <!-- Order branch -->
                        <template v-slot:item.order_branch="{ item }">
                            {{ item.order_branch ? item.order_branch.branch_name : "Unassigned" }}
                        </template>
                         <!-- Production Status -->
                         <template v-slot:item.production_status="{ item }">
                            <v-chip :color="getProductionStatusColor(item.production_status)" small>
                                {{ item.production_status }}
                            </v-chip>
                        </template>
                        <!-- Payment Status -->
                        <template v-slot:item.full_payment_received="{ item }">
                            <!-- With paymentList feature -->
                            <span v-if="item.order_date >= featureFlags.paymentListFeature && item.payments" @click="customerPaymentsDialog = true, selectedOrder = item">
                                <div v-if="item.full_payment_received">
                                    <v-chip color="green lighten-4" class="hover-pointer" small>Fully Paid</v-chip>
                                </div>
                                <div v-else>
                                    <v-chip color="red lighten-4" class="hover-pointer" small>
                                        Due: {{ formatAsCurrency("R", (item.order_value - item.amount_paid)) }}
                                    </v-chip>
                                </div>
                            </span>
                            <!-- Without paymentList feature -->
                            <span v-else>
                                <div v-if="item.full_payment_received">
                                    <v-chip color="green lighten-4" small>Fully Paid</v-chip>
                                </div>
                                <div v-else>
                                    <v-chip color="red lighten-4" small>
                                        Due: {{ formatAsCurrency("R", (item.order_value - item.amount_paid)) }}
                                    </v-chip>
                                </div>
                            </span>
                        </template>
                        <!-- Delivery Fee -->
                        <template v-slot:item.order_delivery_fee="{ item }">
                            <v-chip v-if="item.order_delivery_fee > 0" color="green lighten-4" small>
                                {{ formatAsCurrency('R', item.order_delivery_fee) }}
                            </v-chip>
                            <v-chip v-else small>Not Set</v-chip>
                        </template>
                        <!-- Delivery Date -->
                        <template v-slot:item.order_delivery_date="{ item }">
                            {{ item.order_delivery_date }}
                        </template>
                        <!-- Action items -->
                        <template v-slot:item.actions="{ item }">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="updateOrderDeliveryDate(item)">
                                        <v-list-item-title>Update Delivery Date <v-icon small right>mdi-calendar</v-icon></v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="item.order_delivery_date" @click="updateOrderDeliveryTime(item)">
                                        <v-list-item-title>Update Delivery Time <v-icon small right>mdi-clock</v-icon></v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="printDeliveryNoteForOrder(item)">
                                        <v-list-item-title>Print Delivery Note <v-icon small right>mdi-printer</v-icon></v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="openUpdateCustomerAddressDialog(item)">
                                        <v-list-item-title>Update Customer Address <v-icon small right>mdi-home-edit</v-icon></v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="openMessageTemplatesDialog(item)">
                                        <v-list-item-title>Message Templates <v-icon small right>mdi-message-text-outline</v-icon></v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <!-- Line Item Expansion -->
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="px-0">
                                <v-sheet elevation="0" class="py-3">
                                    <v-container>
                                        <v-row>
                                            <v-col md="3" sm="3">
                                                <h4>Item name</h4>
                                            </v-col>
                                            <v-col md="3" sm="3">
                                                <h4>Variant</h4>
                                            </v-col>
                                            <v-col md="2" sm="2">
                                                <h4>Quantity</h4>
                                            </v-col>
                                            <v-col md="2" sm="2">
                                                <h4>Production Plan</h4>
                                            </v-col>
                                            <v-col md="2" sm="2">
                                                <h4>Production Completed</h4>
                                            </v-col>
                                        </v-row>
                                        <div
                                            v-for="(line_item, index) in item.line_items"
                                            :key="index"
                                        >
                                            <v-row class="py-2">
                                                <v-col md="3" sm="3">
                                                    {{ line_item.product_name }}
                                                </v-col>
                                                <v-col md="3" sm="3">
                                                    {{ line_item.variant ? `${line_item.variant.variant_set_name} - ${line_item.variant.variant_name}` : '' }}
                                                </v-col>
                                                <v-col md="2" sm="2">
                                                    {{ line_item.item_qty }}
                                                </v-col>
                                                <v-col md="2" sm="2">
                                                    <v-chip :color="line_item.assigned_to_plan ? 'green lighten-4' : 'red lighten-4'" small>
                                                        <v-progress-circular v-if="line_item.loading" indeterminate color="primary" size="16"></v-progress-circular>
                                                        <template v-else>
                                                            {{ line_item.assigned_to_plan ? line_item.plan_number || 'Loading...' : 'Unassigned' }}
                                                        </template>
                                                    </v-chip>
                                                </v-col>
                                                <v-col md="2" sm="2">
                                                    {{ line_item.production_completed }}
                                                </v-col>
                                            </v-row>
                                            <v-divider v-if="index < item.line_items.length - 1"></v-divider>
                                        </div>
                                    </v-container>
                                </v-sheet>
                            </td>
                        </template>
                    </v-data-table>
                </v-sheet>
            </v-col>
        </v-row>

        <!-- Update Delivery Date Dialog -->
        <v-dialog v-model="dialogState[selectedOrder.order_id]" persistent max-width="400px">
            <v-card>
                <v-card-title class="text-center">
                    <span class="text-h5">Update Delivery Date - {{ selectedOrder.order_number }}</span>
                </v-card-title>
                <v-card-text class="pt-0">
                    <v-date-picker
                        v-model="deliveryDate"
                        no-title
                        width="100%"
                        :events="set21DayDate"
                    >
                    </v-date-picker>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="clearDeliveryDate">Clear Delivery Date</v-btn>
                    <v-btn color="blue darken-1" text @click="cancelDeliveryDate">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="submitDeliveryDate">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Update Delivery Time Dialog -->
        <v-dialog v-model="deliveryTimeDialog" max-width="400px">
            <v-card>
                <v-card-title class="text-h6">
                    Update Delivery Time
                </v-card-title>
                <v-card-text>
                    <v-select
                        v-model="deliveryTime"
                        :items="timeOptions"
                        label="Select Time"
                        dense
                        outlined
                    ></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="selectedOrder && selectedOrder.order_delivery_time" color="red darken-1" text @click="clearDeliveryTime">Clear Delivery Time</v-btn>
                    <v-btn color="blue darken-1" text @click="cancelDeliveryTime">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="submitDeliveryTime">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Delivery Address-->
        <v-dialog v-model="updateCustomerAddressDialog" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Update Customer Address</v-card-title>
                <v-card-text>
                    <v-text-field v-model="customerAddress1" label="Address Line 1" outlined dense></v-text-field>
                    <v-text-field v-model="customerAddress2" label="Address Line 2" outlined dense></v-text-field>
                    <v-text-field v-model="customerCity" label="City" outlined dense></v-text-field>
                    <v-text-field v-model="customerZip" label="Zip Code" outlined dense></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="updateCustomerAddressDialog = false">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="updateCustomerAddress">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Message templates dialog-->
        <v-dialog v-model="messageTemplatesDialog" max-width="600px">
            <v-card>
                <v-card-title class="text-h5">Message Templates</v-card-title>
                <v-card-text>
                    <v-select
                        v-model="selectedTemplate"
                        :items="messageTemplates"
                        label="Select a template"
                        item-text="name"
                        item-value="template"
                        return-object
                        @change="populateTemplate"
                    ></v-select>
                    <v-textarea
                        v-if="selectedTemplate"
                        v-model="populatedTemplate"
                        label="Message"
                        rows="10"
                    ></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="copyToClipboard">Copy to Clipboard</v-btn>
                    <v-btn color="red darken-1" text @click="messageTemplatesDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Customer Details Dialog -->
        <v-dialog v-model="customerDetailsDialog" max-width="400px">
            <v-card v-if="selectedCustomer">
                <v-card-title class="text-h5">{{ selectedCustomer.customer_name }}</v-card-title>
                <v-card-text>
                    <div class="mt-2">Cell: {{ selectedCustomer.customer_cell || "Not set" }}</div>
                    <div>Email: {{ selectedCustomer.customer_email || "Not set" }}</div>
                    <div class="mt-2" v-if="selectedCustomer.customer_addr_1">Address Line 1: {{ selectedCustomer.customer_addr_1 }}</div>
                    <div v-if="selectedCustomer.customer_addr_2">Address Line 2: {{ selectedCustomer.customer_addr_2 }}</div>
                    <div v-if="selectedCustomer.customer_city">City: {{ selectedCustomer.customer_city }}</div>
                    <div v-if="selectedCustomer.customer_zip">Zip: {{ selectedCustomer.customer_zip }}</div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="customerDetailsDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Customer Payments Dialog -->
        <v-dialog v-model="customerPaymentsDialog" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Customer Payments</v-card-title>
                    <v-list dense>
                        <!-- Payments list -->
                        <v-list-item
                            v-for="(payment, index) in selectedOrder.payments"
                            :key="payment.id"
                        >
                            <v-list-item-content>
                            <v-row align="center" no-gutters>
                                <v-col cols="1">{{ index + 1 }}.</v-col>
                                <v-col cols="4">{{ payment.date }}</v-col>
                                <v-col cols="3">{{ payment.type }}</v-col>
                                <v-col cols="3" class="text-right">{{ formatAsCurrency("R", payment.amount) }}</v-col>
                            </v-row>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="customerPaymentsDialog = false, selectedOrder = {}">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import db from '../../../components/firebaseInit';
import firebase from "firebase";
import { printDeliveryNote } from '../functions/printDeliveryNote.js';
import { featureFlags } from '../../../composables/featureFlags.js';

export default {
    name: "OrderFulfillmentDashboard",
    data() {
        return {
            featureFlags,
            search: '',
            selectedBranch: [],
            selectedPaymentStatus: '',
            paymentStatusOptions: ['Fully Paid', 'Partially Paid', 'Not Paid'],
            selectedProductionStatus: [],
            productionStatusOptions: [
                'All Items Assigned',
                'Partially Assigned',
                'No Items Assigned',
            ],
            sales_orders: [],
            branches: [],
            orderTableHeaders: [
                { text: 'Order #', value: 'order_number' },
                { text: 'Customer', value: 'customer.customer_name' },
                { text: 'Branch', value: 'order_branch' },
                { text: 'Date', value: 'order_date' },
                { text: 'Payment Status', value: 'full_payment_received' },
                { text: 'Production Status', value: 'production_status' },
                { text: 'Delivery Fee', value: 'order_delivery_fee' },
                { text: 'Delivery Date', value: 'order_delivery_date' },
                { text: 'Delivery Time', value: 'order_delivery_time' },
                { text: 'Actions', value: 'actions', align: 'right' },
            ],
            selectedDeliveryDate: null,
            dialogState: {},
            deliveryDateMenu: false,
            deliveryDate: '',
            deliveryTimeDialog: false,
            deliveryTime: '',
            selectedOrder: {},
            timeOptions: this.generateTimeOptions(),
            //
            companyName: 'Pascal Furniture',
            companyAddress: 'Unit 3, 21 Marconi Road, Monatgue Gardens 7441',
            companyPhone: '079 895 4589',
            companyLegalName: 'Pascal Marlin (Pty) Ltd',
            companyTradingAs: 'Pascal Furniture',
            companyRegNumber: '2020/262421/07',
            companyPhysicalAddress: 'Unit 3, 21 Marconi Road, Monatgue Gardens 7441',
            //
            updateCustomerAddressDialog: false,
            customerAddress1: '',
            customerAddress2: '',
            customerCity: '',
            customerZip: '',
            //
            messageTemplatesDialog: false,
            messageTemplates: [],
            selectedTemplate: null,
            populatedTemplate: '',
            //
            selectedCustomer: null,
            customerDetailsDialog: false,
            customerPaymentsDialog: false,
        }
    },
    computed: {
        filteredSalesOrders() {
            return this.sales_orders.filter(order => {
                const searchMatch = order.order_number.toLowerCase().includes(this.search.toLowerCase()) ||
                                    order.customer.customer_name.toLowerCase().includes(this.search.toLowerCase());
                                    const branchMatch = !this.selectedBranch.length || this.selectedBranch.includes(order.order_branch ? order.order_branch.branch_name : 'Unassigned');


                // Apply the paymentList feature flag
                const enablePaymentListFeature = order.order_date >= this.featureFlags.paymentListFeature && order.payments;
                
                let paymentStatusMatch;

                if (enablePaymentListFeature) {
                    // Use payment list if feature is enabled
                    paymentStatusMatch = !this.selectedPaymentStatus || 
                        (this.selectedPaymentStatus === 'Fully Paid' && order.full_payment_received) ||
                        (this.selectedPaymentStatus === 'Partially Paid' && !order.full_payment_received && order.amount_paid > 0) ||
                        (this.selectedPaymentStatus === 'Not Paid' && !order.full_payment_received && order.amount_paid === 0);
                } else {
                    // Use order deposit and balance amounts if paymentList feature doesn't apply to order
                    paymentStatusMatch = !this.selectedPaymentStatus || 
                        (this.selectedPaymentStatus === 'Fully Paid' && order.full_payment_received) ||
                        (this.selectedPaymentStatus === 'Partially Paid' && !order.full_payment_received && (order.deposit_recieved > 0 || order.balance_recieved > 0)) ||
                        (this.selectedPaymentStatus === 'Not Paid' && !order.full_payment_received && order.deposit_recieved === 0 && order.balance_recieved === 0);
                }

                const productionStatusMatch = !this.selectedProductionStatus.length ||
                    this.selectedProductionStatus.some(status => {
                        if (status === 'All Items Assigned') {
                            return order.production_status.startsWith(order.line_items.length);
                        } else if (status === 'Partially Assigned') {
                            return !order.production_status.startsWith('0') && !order.production_status.startsWith(order.line_items.length);
                        } else if (status === 'No Items Assigned') {
                            return order.production_status.startsWith('0');
                        }
                        return false;
                    });
                const deliveryDateMatch = !this.selectedDeliveryDate || order.order_delivery_date === this.selectedDeliveryDate;
                return searchMatch && branchMatch && paymentStatusMatch && productionStatusMatch && deliveryDateMatch;
            });
        },
        //
        isDialogVisible: {
            get() {
                return this.dialogState.visible;
            },
            set(value) {
                if (!value) {
                    this.dialogState = {
                        visible: false,
                        orderId: null,
                    };
                }
            },
        },
    },
    methods: {
        getSalesOrders() {
            const docRef = db.collection("sales_orders").where("order_status", "==", "Confirmed").orderBy("order_number");

            try {
                docRef.onSnapshot((snapshot) => {
                    this.sales_orders = snapshot.docs.map(doc => {
                        const order = { ...doc.data() };
                        order.production_status = this.calculateProductionStatus(order.line_items);

                        // Apply the paymentList feature flag to determine if order is fully paid
                        const enablePaymentListFeature = order.order_date >= this.featureFlags.paymentListFeature && order.payments;

                        // Payment status calculations   
                        order.amount_paid = enablePaymentListFeature
                        ? order.payments.reduce((sum, payment) => sum + Number(payment.amount), 0)
                        : order.deposit_recieved + order.balance_recieved
                            
                        order.full_payment_received = order.order_value === order.amount_paid;

                        return order;
                    })
                });
            } catch(error) {
                console.log(error);
            }
        },
        getBranchList() {
            const docRef = db.collection("branches").orderBy("branch_name");

            try {
                docRef.onSnapshot((snapshot) => {
                    this.branches = snapshot.docs.map((doc) => ({ ...doc.data() }));
                });
            } catch(error) {
                console.error(error);
            }
        },
        calculateProductionStatus(lineItems) {
            const totalItems = lineItems.length;
            const assignedItems = lineItems.filter(item => item.assigned_to_plan).length;
            return `${assignedItems} / ${totalItems} Items Assigned`;
        },
        getProductionStatusColor(status) {
            const [assigned, total] = status.split(' / ').map(Number);
            if (assigned === total) {
                return 'green lighten-4';
            } else if (assigned > 0) {
                return 'amber lighten-4';
            } else {
                return 'red lighten-4';
            }
        },
        fetchProductionPlanDetails({ item, value }) {
            if (!value) return;
            // Loop through the line_items of the expanded order
            const { order_id, line_items } = item;

            const orderIndex = this.sales_orders.findIndex((order) => order.order_id === order_id);

            line_items.forEach(async ({ assigned_to_plan, plan_number }, index) => {
                if (!assigned_to_plan || plan_number) return;
                // Set loading state to true
                this.$set(this.sales_orders[orderIndex].line_items[index], 'loading', true);

                // Fetch production plan details from the database
                const planRef = db.collection("production_plans").doc(assigned_to_plan);
                try {
                    // Set up a real-time listener for the production plan
                    planRef.onSnapshot((doc) => {
                        if (doc.exists) {
                            this.$set(this.sales_orders[orderIndex].line_items[index], 'plan_number', doc.data().plan_number);
                            this.$set(this.sales_orders[orderIndex].line_items[index], 'production_completed', doc.data().plan_status === "Completed" ? "Yes" : "No");
                        }
                    });
                } catch(error) {
                    console.error("Error fetching production plan details:", error);
                } finally {
                    // Set loading state to false
                    this.$set(this.sales_orders[orderIndex].line_items[index], 'loading', false);
                }
            });
        },
        //
        formatAsCurrency (currency, inputVal) {
            return `${currency} ${new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            .format(inputVal)}`
        },
        //
        updateOrderDeliveryDate(order) {
            this.selectedOrder = order;
            this.deliveryDate = order.order_delivery_date;
            this.$set(this.dialogState, order.order_id, true);
        },
        //
        async submitDeliveryDate() {
            const docRef = db.collection("sales_orders").doc(this.selectedOrder.order_id);

            try {
                await docRef.update({ order_delivery_date: this.deliveryDate });
                this.$set(this.dialogState, this.selectedOrder.order_id, false);
                this.selectedOrder = {};
            } catch(error) {
                console.error(error);
            }
        },
        updateOrderDeliveryTime(order) {
            this.selectedOrder = order;
            this.deliveryTime = order.order_delivery_time || '';
            this.deliveryTimeDialog = true;
        },
        async submitDeliveryTime() {
            const docRef = db.collection("sales_orders").doc(this.selectedOrder.order_id);
            try {
                await docRef.update({ order_delivery_time: this.deliveryTime });
                this.deliveryTimeDialog = false;
                this.selectedOrder = {};
            } catch (error) {
                console.error("Error updating delivery time:", error);
            }
        },
        cancelDeliveryDate() {
            this.$set(this.dialogState, this.selectedOrder.order_id, false);
            this.selectedOrder = {};
        },
        //
        clearDeliveryDate() {
            if(!confirm("Are you sure you want to remove the delivery date from this order?")) return;
            const docRef = db.collection("sales_orders").doc(this.selectedOrder.order_id);
            try {
                docRef.update({ order_delivery_date: firebase.firestore.FieldValue.delete() });
                this.deliveryDate = '';
                this.$set(this.dialogState, this.selectedOrder.order_id, false);
                this.selectedOrder = {};
            } catch (error) {
                console.error("Error clearing delivery date:", error);
            }
        },
        //
        cancelDeliveryTime() {
            this.deliveryTime = '';
            this.deliveryTimeDialog = false;
            this.selectedOrder = {};
        },
        clearDeliveryTime() {
            if(!confirm("Are you sure you want to remove the delivery time from this order?")) return;
            const docRef = db.collection("sales_orders").doc(this.selectedOrder.order_id);
            try {
                docRef.update({ order_delivery_time: firebase.firestore.FieldValue.delete() });
                this.deliveryTime = '';
                this.deliveryTimeDialog = false;
                this.selectedOrder = {};
            } catch (error) {
                console.error("Error clearing delivery date:", error);
            }
        },
        addDaysToDate(dateString, days) {
            const date = new Date(dateString);
            date.setDate(date.getDate() + days);
            return date.toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }); // Format as 'YYYY-MM-DD'
        },
        //
        set21DayDate(date) {
            if (!this.selectedOrder || !this.selectedOrder.order_date || this.selectedOrder.order_delivery_date) {
                return false; // No selected order or order date
            }

            const datePlus21Days = this.addDaysToDate(this.selectedOrder.order_date, 21);
            return date === datePlus21Days ? ['green'] : false; // Mark the date 21 days after the order date
        },
        //
        generateTimeOptions() {
            const options = [];
            for (let hour = 8; hour <= 19; hour++) { // Start from 08:00 and end before 19:00
                for (let minute = 0; minute < 60; minute += 30) { // 15-minute intervals
                    const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                    options.push(time);
                }
            }
            return options;
        },
        printDeliveryNoteForOrder(order) {
            const companyDetails = {
                name: this.companyName,
                address: this.companyAddress,
                phone: this.companyPhone,
                legalName: this.companyLegalName,
                tradingAs: this.companyTradingAs,
                regNumber: this.companyRegNumber,
                physicalAddress: this.companyPhysicalAddress,
            };
            printDeliveryNote(order, companyDetails);
        },
        openUpdateCustomerAddressDialog(order) {
            this.selectedOrder = order;
            this.customerAddress1 = order.customer?.customer_addr_1 || '';
            this.customerAddress2 = order.customer?.customer_addr_2 || '';
            this.customerCity = order.customer?.customer_city || '';
            this.customerZip = order.customer?.customer_zip || '';
            this.updateCustomerAddressDialog = true;
        },
        //
        async updateCustomerAddress() {
            const salesOrderDocRef = db.collection("sales_orders").doc(this.selectedOrder.order_id);
            const customerDocRef = db.collection("customers").doc(this.selectedOrder.customer.customer_id);

            // Start a batch
            const batch = db.batch();

            // Update the sales order document
            batch.update(salesOrderDocRef, {
                'customer.customer_addr_1': this.customerAddress1,
                'customer.customer_addr_2': this.customerAddress2,
                'customer.customer_city': this.customerCity,
                'customer.customer_zip': this.customerZip,
            });

            // Update the customer document
            batch.update(customerDocRef, {
                'customer_addr_1': this.customerAddress1,
                'customer_addr_2': this.customerAddress2,
                'customer_city': this.customerCity,
                'customer_zip': this.customerZip,
            });

            try {
                // Commit the batch
                await batch.commit();
                this.updateCustomerAddressDialog = false;
                // Reset the address fields
                this.customerAddress1 = '';
                this.customerAddress2 = '';
                this.customerCity = '';
                this.customerZip = '';
            } catch (error) {
                console.error("Error updating customer address:", error);
            }
        },
        openMessageTemplatesDialog(order) {
            this.selectedOrder = order;
            this.fetchMessageTemplates();
            this.messageTemplatesDialog = true;
        },
        fetchMessageTemplates() {
            db.collection("message_templates").orderBy('name').get().then(querySnapshot => {
                this.messageTemplates = querySnapshot.docs.map(doc => ({
                    name: doc.data().name,
                    template: doc.data().message
                }));
            });
        },
        populateTemplate(template) {
            if (template && this.selectedOrder) {
                this.populatedTemplate = eval('`' + template.template + '`');
            } else {
                this.populatedTemplate = '';
            }
        },
        copyToClipboard() {
            if (this.populatedTemplate) {
                navigator.clipboard.writeText(this.populatedTemplate).then(() => {
                    this.$toast.success('Message copied to clipboard');
                }, () => {
                    this.$toast.error('Failed to copy message');
                });
            }
        },
        openCustomerDetailsDialog(customer) {
            this.selectedCustomer = customer;
            this.customerDetailsDialog = true;
        },
    },
    watch: {
        search(newVal) {
            if (!newVal && this.$refs.searchField) {
                this.$refs.searchField.internalValue = ''; // Manually clear the input field
            }
        },
        messageTemplatesDialog(newValue) {
            if (!newValue) {
                // Reset the selected template and populated message when the dialog closes
                this.selectedTemplate = null;
                this.populatedTemplate = '';
            }
        },
    },
    created() {
        this.getSalesOrders();
        this.getBranchList();
    }
}
</script>

<style scoped>
.customer-name:hover {
    cursor: pointer;
    border-bottom: 1px solid;
}

.hover-pointer:hover {
    cursor: pointer;
}
</style>